import { AllocateType, CostFrequency } from "@/Simulation/enums"
import {
    AnyPerformanceData,
    CostProfile,
    DeviceCapitalCost,
    DeviceMaintenanceCost,
    NodeCapitalCost,
    NodeMaintenanceCost
} from "@/Simulation/interfaces"
import { CType, LDist, PowerState } from "@/core/enums"
import { NodeReferenceHelper } from "@/core/helpers"
import { ProvisioningEvent } from "@/core/interfaces/ProvisioningEvent"
import ApiService from "@/core/services/api.service"
import { Models, Services } from "@ekko/predict-client-api"
import { DeviceModelType, Interface } from "predict-performance-calculation"
import { PerformanceParameter } from "../factory/PerformanceParameter"
import { NodeParameterBase } from "./NodeParameterBase"

/**
 *
 */
interface DeviceModesParameter {
    /**
     *
     */
    calibrated: Models.DeviceModeModel | null
    /**
     *
     */
    default: Models.DeviceModeModel | null
}

/**
 *
 */
export class ItemParameter extends NodeParameterBase<Models.ItemModel, Interface.Item> {
    /**
     *
     */
    static _mitigation_knownStoredDeviceModes: Map<string, boolean> | null = null

    /**
     * If the device mode is missing, returns it,
     *
     * @param entity
     * @param knownStoredDeviceModes
     * @returns
     */
    private static _mitigation_missingDeviceMode(entity: Models.ItemModel | Models.CustomDeviceModeModel | null, knownStoredDeviceModes: Map<string, boolean>) {
        const relationship = entity?.data.relationships?.deviceMode?.data as Models.DeviceModeModel | null
        if(relationship && !knownStoredDeviceModes.get(relationship.id)) {
            return relationship
        } else {
            return null
        }
    }

    /**
     * MITIGATION: it's possible in very specific circumstances to add a
     * non-existent device mode to an item. This removes it if that is the
     * case.
     *
     * @param item
     */
    static async _mitigation_removeNonExistentDeviceModes(item: Models.ItemModel) {
        if(!ItemParameter._mitigation_knownStoredDeviceModes) {
            const service = await ApiService.getDeviceModeService()
            const cache = new Map()
            for await (const deviceModePage of service.getAllDeviceModesPaginated()) {
                for(const deviceMode of deviceModePage) {
                    cache.set(deviceMode.id, true)
                }
            }
            ItemParameter._mitigation_knownStoredDeviceModes = cache
        }
        const missingRelationship = this._mitigation_missingDeviceMode(item,
            ItemParameter._mitigation_knownStoredDeviceModes)
        if(missingRelationship) {
            console.error(
                `Item ${item.id} uses non-existent device mode ${missingRelationship.id}, will remove`
            )

            const service = await ApiService.getItemService()
            console.error(`Allocating a placeholder device mode`)
            for(const id of ItemParameter._mitigation_knownStoredDeviceModes.keys()) {
                const dmService = await ApiService.getDeviceModeService()
                item.addDeviceMode(await dmService.getDeviceMode(id))
                break
            }
            await service.updateItem(item)
        }
        const customDeviceMode = await item.getCustomDeviceMode()
        if(customDeviceMode) {
            const missingRelationship = this._mitigation_missingDeviceMode(
                customDeviceMode,
                ItemParameter._mitigation_knownStoredDeviceModes)
            if(missingRelationship) {
                console.error(
                    `Item ${item.id} custom device mode uses non-existent device mode ${missingRelationship.id}, will remove`
                )

                const service = await ApiService.getItemService()
                item.removeCustomDeviceMode(customDeviceMode)
                await service.updateItem(item)
            }
        }

        if(missingRelationship && !item.data.relationships?.deviceMode?.data) {
            console.error(`Allocating a placeholder device mode`)
            for(const id of ItemParameter._mitigation_knownStoredDeviceModes.keys()) {
                const service = await ApiService.getDeviceModeService()
                item.addDeviceMode(await service.getDeviceMode(id))
                const itemService = await ApiService.getItemService()
                await itemService.updateItem(item)
                break
            }
        }
    }

    async fetch(item: Models.ItemModel) {

        const performanceData = await this.getPerformanceData(item)

        const deviceModeData = await this.getDeviceModeData(item)

        const resilience = await this.getResilience(item)

        const costProfile = await this.getCostProfile(item)

        const provisioningEvents = await this.getProvisioningEvents(item)

        return {
            ...await this.fetchBasic(item),
            ...await NodeReferenceHelper.getSupplierReference(item),
            deviceMode: {
                performanceData,
                ...deviceModeData.deviceMode
            },
            deviceModelType: deviceModeData.deviceModelType,
            resilience,
            costProfile,
            provisioningEvents
        } as Interface.Item & {deviceModelType: DeviceModelType, id: string}
    }

    /**
     * Retrieves the item/It node cost profile
     * @param node
     */
    async getCostProfile(node: Models.ItemModel): Promise<CostProfile | null> {

        let costProfile: CostProfile | null = null

        const nodeCostProfile = await (node as Models.ItemModel).getCostProfile()

        if (nodeCostProfile) {

            costProfile = {} as CostProfile

            costProfile.allocateType = nodeCostProfile.getAllocationType() as AllocateType


            costProfile.deviceCapitalCosts = []
            costProfile.deviceMaintenanceCosts = []


            const deviceCapitalCosts = await nodeCostProfile.getDeviceCapitalCosts()
            for await (const deviceCapitalCost of deviceCapitalCosts) {

                const cost = {
                    type: "deviceCapitalCost",
                    name: deviceCapitalCost.getName(),
                    amount: deviceCapitalCost.getAmount(),
                    lifetimeYears: deviceCapitalCost.getLifetimeYears()
                } as DeviceCapitalCost

                costProfile.deviceCapitalCosts.push(cost)
            }

            const deviceMaintenanceCosts = await nodeCostProfile.getDeviceMaintenanceCosts()
            for await (const deviceMaintenanceCost of deviceMaintenanceCosts) {

                const cost = {
                    type: "deviceMaintenanceCost",
                    name: deviceMaintenanceCost.getName(),
                    amount: deviceMaintenanceCost.getAmount(),
                    frequency: deviceMaintenanceCost.getFrequency() as CostFrequency

                } as DeviceMaintenanceCost

                costProfile.deviceMaintenanceCosts.push(cost)
            }

            costProfile.nodeCapitalCosts = []
            costProfile.nodeMaintenanceCosts = []

            const nodeCapitalCosts = await nodeCostProfile.getNodeCapitalCosts()
            for await (const nodeCapitalCost of nodeCapitalCosts) {

                const cost = {
                    type: "nodeCapitalCost",
                    name: nodeCapitalCost.getName(),
                    amount: nodeCapitalCost.getAmount(),
                    lifetimeYears: nodeCapitalCost.getLifetimeYears(),
                    startDate: new Date(nodeCapitalCost.getStartDate() as string)

                } as NodeCapitalCost

                costProfile.nodeCapitalCosts.push(cost)
            }

            const nodeMaintenanceCosts = await nodeCostProfile.getNodeMaintenanceCosts()
            for await (const nodeMaintenanceCost of nodeMaintenanceCosts) {

                const cost = {
                    type: "nodeMaintenanceCost",
                    name: nodeMaintenanceCost.getName(),
                    amount: nodeMaintenanceCost.getAmount(),
                    startDate: new Date(nodeMaintenanceCost.getStartDate() as string),
                    endDate: new Date(nodeMaintenanceCost.getEndDate() as string),
                    frequency: nodeMaintenanceCost.getFrequency() as CostFrequency


                } as NodeMaintenanceCost

                costProfile.nodeMaintenanceCosts.push(cost)
            }
        }

        return costProfile

    }

    /**
     * Retrieves the Resilience from item
     * @param item
     */
    async getResilience(item: Models.ItemModel) {

        let resilience: Interface.Resilience | null = null
        const itemResilience = await item.getResilience()

        if (itemResilience) {

            resilience = {
                offAtZeroLoad: itemResilience.getOffAtZeroLoad() as boolean,
                operatingLimit: itemResilience.getOperatingLimit() as number,
                loadDistribution: itemResilience.getLoadDistribution() as LDist,
                idleState: itemResilience.getIdleState() as PowerState
            } as Interface.Resilience

            const loadStaging = await itemResilience.getResilienceLoadStaging()

            if (loadStaging) {

                resilience.resilienceLoadStaging = {
                    triggerLoad: loadStaging.getTriggerLoad() as number,
                    stageGroupSize: loadStaging.getStageGroupSize() as number
                }
            }

            const spareCapacity = await itemResilience.getResilienceSpareCapacity()

            if (spareCapacity) {
                resilience.resilienceSpareCapacity = {
                    spareCapacity: spareCapacity.getSpareCapacity() as number,
                    spareCapacityType: spareCapacity.getSpareCapacityType() as CType
                }
            }

            const grouping = await itemResilience.getResilienceGrouping()

            if (grouping) {

                const resilienceGrouping = {} as Interface.ResilienceGrouping
                const groupingStandBy = await grouping.getResilienceGroupingStandby()

                if (groupingStandBy) {

                    resilienceGrouping.resilienceGroupingStandby = {
                        activeState: groupingStandBy.getActiveState() as PowerState,
                        idleState: groupingStandBy.getIdleState() as PowerState
                    }
                }

                resilienceGrouping.groups = grouping.getGroups() as number
                resilience.resilienceGrouping = resilienceGrouping
            }

        }

        return resilience

    }

    /**
     * Gets calibrated or default device mode for an item
     * @param item
     */
    async getDeviceModes(item: Models.ItemModel): Promise<DeviceModesParameter> {
        if(sessionStorage.enableMissingDeviceModeMitigation) {
            // MITIGATION / WORKAROUND
            await ItemParameter._mitigation_removeNonExistentDeviceModes(item)
            //
        }

        const defaultDeviceMode = await item.getDeviceMode()
        const customDeviceModeService = (await ApiService.getService(
            "CustomDeviceModeService"
        )) as Services.CustomDeviceModeService

        let customDeviceModeId: string | null = null
        const customDeviceMode = await item.getCustomDeviceMode()
        if (customDeviceMode) {
            customDeviceModeId = customDeviceMode.id
        }
        let calibratedDeviceMode
        if (customDeviceModeId) {
            const customDeviceMode: Models.CustomDeviceModeModel = await customDeviceModeService.getCustomDeviceMode(customDeviceModeId)

            if (customDeviceMode.getActive()) {
                let deviceModeId: string | null = null
                const deviceMode = await customDeviceMode.getDeviceMode()
                if (deviceMode) {
                    deviceModeId = deviceMode.id
                    calibratedDeviceMode = await (await ApiService.getService("DeviceModeService") as Services.DeviceModeService).getDeviceMode(deviceModeId)
                    return { default: defaultDeviceMode, calibrated: calibratedDeviceMode }
                }
            }
        }
        return { default: defaultDeviceMode, calibrated: null }
    }

    /**
     * Returns the performance data
     * @param item
     */
    async getDeviceModeData(item: Models.ItemModel) {

        let deviceModelType: string | null = null

        const modes = await this.getDeviceModes(item)

        const deviceMode = modes.calibrated ?? modes.default

        const idleDraw = deviceMode?.getIdleDraw()
        const nameplateDraw = deviceMode?.getNameplateDraw()
        const peakDraw = deviceMode?.getPeakDraw()


        const deviceModel = await modes.default?.getDeviceModel()
        deviceModelType = deviceModel?.getDeviceModelType() ?? null

        return { deviceMode: {idleDraw, nameplateDraw, peakDraw}, deviceModelType }

    }

    /**
     * Returns the performance data
     * @param item
     */
    async getPerformanceData(item: Models.ItemModel): Promise<AnyPerformanceData | null> {
        const modes = await this.getDeviceModes(item)

        const mode = modes.calibrated ?? modes.default
        if(!mode) {
            return null
        }

        const performanceHandler = await PerformanceParameter.factory(mode)
        return performanceHandler.fetch()
    }

    /**
     * Retrieves item's list of Provisioning Events
     * @param item
     */
    async getProvisioningEvents(item: Models.ItemModel) {
        let provisioningEventsUnsorted: ProvisioningEvent[] | null = null

        for await (const event of item.getProvisioningEvents()) {
            const newEvent = {
                date: event.getDate(),
                count: event.getCount(),
                designCapacity: event.getDesignCapacity(),
                designCount: event.getDesignCount(),
            } as ProvisioningEvent

            if (!provisioningEventsUnsorted) {
                provisioningEventsUnsorted = []
            }
            provisioningEventsUnsorted.push(newEvent)
        }

        return provisioningEventsUnsorted?.sort(
            (a, b) => (a.date ?? "") < (b.date ?? "") ? -1 :
                (a.date === b.date ? 0 : 1)
        )
    }

}