import { vueLazySetMap, VueLazyWrapper } from "@/core/helpers"
import { Paginated } from "@/core/helpers/Paginated"
import ApiService from "@/core/services/api.service"
import type { Models } from "@ekko/predict-client-api"
import { ActionTree, GetterTree, MutationTree } from "vuex"
import { PaginatedResult } from "../../core/interfaces/PaginatedResult"
import { FiltersModule } from "../common/filters"
import { FiltersState } from "../common/filters/types"
import { PaginationModule } from "../common/pagination"
import { PaginationState } from "../common/pagination/types"
import { DeviceModeInfo } from "../device-modes/types"

class State {
    devices: Models.DeviceModelModel[] = []
    loaded = false

    /**
     *
     */
    modelManufacturers: Map<string, VueLazyWrapper<Models.ManufacturerModel>> = new Map()

    /**
     *
     */
    modelModes: Map<string, VueLazyWrapper<Models.DeviceModeModel | undefined>> = new Map()
}

const getters: GetterTree<State & FiltersState & PaginationState, any> = {
    /**
     * @param state
     */
    deviceModelsWithManufacturers(state): (defunct: boolean) => DeviceModeInfo[] | null {
        return (defunct: boolean) => {
            const out: DeviceModeInfo[] = []
            const deviceModels = state.devices.filter(device => device.getDefunct() == defunct)
            for (const device of deviceModels) {
                if (!state.modelManufacturers.has(device.id)) {
                    vueLazySetMap(
                        state.modelManufacturers,
                        device.id,
                        () => device.getManufacturer()
                    )
                }

                const manufacturer = state.modelManufacturers.get(device.id)?.value

                if (!state.modelModes.has(device.id)) {
                    vueLazySetMap(
                        state.modelModes,
                        device.id,
                        async () => {
                            for await (const mode of device.getDeviceModes()) {
                                return mode
                            }
                            console.error(`Device model with ID ${device.id} has no modes`)
                            return undefined
                        })
                }

                const mode = state.modelModes.get(device.id)?.value

                if (mode) {
                    out.push({ id: mode.id, model: device, mode, manufacturer, activeMode: mode })
                }
            }
            return out
        }
    },
    /**
     * @param state
     * @returns
     */
    deviceModelsWithManufacturersFilter(state): (a: DeviceModeInfo) => boolean {
        const regexp = new RegExp(state.searchText, "i")
        return ({ model, manufacturer }) => (
            // text
            (
                model?.getDeviceModelType()?.match(regexp) ||
                manufacturer?.getName()?.match(regexp) ||
                model?.getName()?.match(regexp) ||
                model?.getDescription()?.match(regexp)
            ) &&
            // Type
            (
                !state.typeFilter ||
                state.typeFilter == 'all' ||
                model?.getDeviceModelType() === state.typeFilter
            ) &&
            // Access
            (
                !state.accessFilter ||
                state.accessFilter == "all" ||
                model?.getAccessLevel() === state.accessFilter
            ) ||
            false
        )
    },
    /**
     * @param state
     * @returns
     */
    deviceModelsWithManufacturersSort(state): (a: DeviceModeInfo, b: DeviceModeInfo) => number {
        const reverse = (state.sort.direction === 'desc')
        switch (state.sort.field) {
            default:
            // Fall through
            case "Function":
                return Paginated.sortUndefinedFirst((o) => o.model?.getDeviceModelType(), reverse,
                    (o) => o.model?.getModel()
                )
            case "Manufacturer":
                return Paginated.sortUndefinedFirst((o) => o.manufacturer?.getName(), reverse,
                    (o) => o.model?.getModel()
                )
            case "Model":
                return Paginated.sortUndefinedFirst((o) => o.model?.getModel(), reverse)
            case "Capacity":
                return Paginated.sortUndefinedFirst((o) => o.mode?.getCapacity(), reverse)
            case "Access":
                return Paginated.sortUndefinedFirst((o) => o.model?.getAccessLevel(), reverse,
                    (o) => o.model?.getModel()
                )
        }
    },
    /**
     *
     * @param state
     * @param getters
     * @returns
     */
    getDeviceModelsWithManufacturers(state, getters): (defunct: boolean) => PaginatedResult<DeviceModeInfo> {
        return (defunct: boolean) => {
            const results = getters.deviceModelsWithManufacturers(defunct)

            return new Paginated(
                results,
                state.page,
                state.pageSize,
                getters.deviceModelsWithManufacturersSort,
                getters.deviceModelsWithManufacturersFilter
            )
        }
    },
    getIsLibraryEmpty(state): (defunct: boolean) => boolean {
        return (defunct: boolean) => state.loaded && state.devices?.filter(device => device.getDefunct() == defunct).length === 0
    },
}

const mutations = <MutationTree<State>>{
    setDeviceModels(state, devices: Models.DeviceModelModel[]): void {
        state.devices = devices
    },
    setLoaded(state, loaded: boolean): void {
        state.loaded = loaded
    },

    /**
     *
     * @param state
     * @param device
     */
    includeDeviceModel(state, device: Models.DeviceModelModel): void {
        state.devices.push(device)
    },
    /**
     * This replaces the model in the store.
     *
     * @param state
     * @param device
     */
    replacedDeviceModel(state, device: Models.DeviceModelModel): void {
        state.devices = state.devices.map(d => (d.id === device.id) ? device : d)
    }
}

const actions = <ActionTree<State, any>>{
    async fetchDeviceModels({ commit }) {
        const service = await ApiService.getDeviceModelService()
        const records = service.getAllDeviceModelsPaginated()
        const recordsFound: Models.DeviceModelModel[] = []
        for await (const itemPage of records) {
            recordsFound.push(...itemPage)
        }
        commit("setDeviceModels", recordsFound)
    },
}

const paginationStore = PaginationModule()
const filtersModule = FiltersModule()

const DeviceModelModule = {
    namespaced: true,
    state: {
        ...paginationStore.state,
        ...filtersModule.state,
        ...new State(),
    },
    getters: {
        ...paginationStore.getters,
        ...filtersModule.getters,
        ...getters
    },
    mutations: {
        ...paginationStore.mutations,
        ...filtersModule.mutations,
        ...mutations
    },
    actions: {
        ...paginationStore.actions,
        ...filtersModule.actions,
        ...actions
    },
}

export default DeviceModelModule
