import { NodeReferenceHelper } from "@/core/helpers/NodeReferenceHelper"
import { Reading, UnbatchEvents } from "@/core/interfaces"
import { jsonRpcService } from "@/core/services/json-rpc.service"
import { SimulationNode } from "@/Simulation/interfaces"
import { ReadingType } from "../enums"
import { HistoryClientBase } from "./HistoryClientBase"
import { Granularity } from "@/Simulation/enums/Granularity"

/**
 *
 */
export abstract class EnergyHistoryClientBase extends HistoryClientBase {

    /**
     * Readinng type
     */
    readingType: ReadingType = ReadingType.POWER_SUPPLY

    /**
     *
     * @param siteId
     * @param nodes
     * @param unbatchCallbacks
     * @returns
     */
    protected async fetchEnergyHistory(siteId: string,
        nodes: SimulationNode[] | undefined, unbatchCallbacks?: UnbatchEvents
    ): Promise<Reading<Date>[]> {

        if (nodes && nodes.length) {
            unbatchCallbacks?.start?.(nodes.length)
            const result: Reading<Date>[] = []

            let progress = 0
            await Promise.all(nodes.map(async node => {
                const nodeReference = NodeReferenceHelper.getNodeReference(node)
                const readings = await this.getNodeReadings(nodeReference)
                result.push(...readings)
                unbatchCallbacks?.progress?.(++progress)
            }))
            unbatchCallbacks?.progress?.(nodes.length)

            return result
        } else {
            unbatchCallbacks?.start?.(1)
            const result = await this.getSiteTotalReadings(siteId)
            unbatchCallbacks?.progress?.(1)
            return result
        }
    }

    /**
     * Call json-rpc service to get Site Total Readings
     * @param siteId
     */
    async getSiteTotalReadings(siteId: string): Promise<Reading<Date>[]> {
        const granularity = this.granularity === Granularity.MONTH ? Granularity.DAY : this.granularity
        const rpcResult = await jsonRpcService.getSiteTotalReadings(siteId, this.period, granularity)
        return rpcResult.filter((reading) => reading.type as string === this.readingType as string).map(
            reading => ({
                ...reading,
                timestamp: new Date(reading.timestamp),
            })
        )
    }

    /**
     * Gets node readings for the active period and granularity
     *
     * @param nodeReference
     */
    async getNodeReadings(nodeReference: string): Promise<Reading<Date>[]> {
        try {
            const [rpcResult] = await jsonRpcService.getNodeReadingsMayBatch(
                {nodeReference, period: this.period,
                    granularity: this.granularity})
            return rpcResult.filter((reading) => reading.type as string === this.readingType as string).map(
                reading => ({
                    ...reading,
                    timestamp: new Date(reading.timestamp),
                })
            )
        } catch(e) {
            console.error(e)
            return []
        }
    }
}