import ApiService from "@/core/services/api.service"
import { Models, Services } from "@ekko/predict-client-api"

/**
 *
 */
export class Preload {
    /**
     *
     */
    private static deviceModelServiceCached?:
        Promise<Services.DeviceModelService>

    /**
     *
     */
    private static deviceModeServiceCached?: Promise<Services.DeviceModeService>

    /**
     *
     */
    private static get deviceModelService() {
        if(!this.deviceModelServiceCached) {
            this.deviceModelServiceCached = ApiService.getDeviceModelService()
        }
        return this.deviceModelServiceCached
    }

    /**
     *
     */
    private static get deviceModeService() {
        if(!this.deviceModeServiceCached) {
            this.deviceModeServiceCached = ApiService.getDeviceModeService()
        }
        return this.deviceModeServiceCached
    }

    /**
     *
     */
    public static fetchDirect = false

    /**
     *
     * @param siteConfiguration
     * @param debug
     */
    static async deviceModes(
        siteConfiguration: Models.SiteConfigurationModel | null, debug = false
    ) {
        if(this.fetchDirect) {
            return this.deviceModesDirect(siteConfiguration, debug)
        } else {
            return this.deviceModesIterated(siteConfiguration)
        }
    }

    /**
     *
     * @param siteConfiguration
     * @param debug
     */
    static async deviceModesDirect(
        siteConfiguration: Models.SiteConfigurationModel | null, debug = false
    ) {
        if (!siteConfiguration) {
            return
        }
        const deviceModeService = await this.deviceModeService
        const deviceModelService = await this.deviceModelService
        const seenDeviceModes = new Set<string>()
        for await (const item of siteConfiguration.getItems()) {
            seenDeviceModes.add(item.identifyDeviceMode().id)
        }
        if (debug) {
            console.log(`Getting ${seenDeviceModes.size} device modes`)
        }
        const deviceModesIterator =
            deviceModeService.getIdentified([...seenDeviceModes])
        const seenDeviceModels = new Set<string>()
        let deviceModeCount = 0
        for await (const deviceMode of deviceModesIterator) {
            deviceModeCount++
            const deviceModel = deviceMode.identifyDeviceModel()
            if (!deviceModel) continue
            seenDeviceModels.add(deviceModel.id)
        }
        if (debug) {
            console.log(`Found ${deviceModeCount}`)
            console.log(`Getting ${seenDeviceModels.size} device models`)
        }
        let deviceModelCount = 0
        const deviceModelsIterator = deviceModelService.getIdentified([...seenDeviceModels])
        for await (const deviceModel of deviceModelsIterator) {
            deviceModelCount++
        }
        if (debug) {
            console.log(`Found ${deviceModelCount}`)
        }
    }

    /**
     *
     * @param siteConfiguration
     */
    static async deviceModesIterated(
        siteConfiguration: Models.SiteConfigurationModel | null
    ) {
        if (!siteConfiguration) {
            return
        }
        for await (const item of siteConfiguration.getItems()) {
            const deviceMode = await item.getDeviceMode()
            await deviceMode.getDeviceModel()
        }
    }
}