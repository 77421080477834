import { SimulationNode } from "@/Simulation/interfaces"
import { NodeReferenceHelper } from "@/core/helpers/NodeReferenceHelper"
import { Interface, NodeType } from "predict-performance-calculation"
import { EnergyHistoryClientBase } from "./EnergyHistoryClientBase"

/**
 * Class for Energy Performance
 */
export abstract class EnergyPerformanceHistoryClient extends EnergyHistoryClientBase {
    /**
     *
     * @param siteId
     * @param nodes
     */
    async fetchITLoad(siteId: string, nodes?: SimulationNode[]): Promise<Map<string, number>> {
        const itLoadByTime = new Map<string, number>()

        if (nodes && nodes.length) {
            const itNodes = nodes.filter((node) => node.type === NodeType.ITNode) as Interface.ITNode[]

            await Promise.all(itNodes.map(async itNode => {
                const nodeReference = NodeReferenceHelper.getNodeReference(itNode)
                const readings = await this.getNodeReadings(nodeReference)

                for (const reading of readings) {
                    const readingKey = new Date(reading.timestamp).toISOString()
                    const oldLoad = itLoadByTime.get(readingKey) ?? 0
                    itLoadByTime.set(readingKey, oldLoad + reading.value)
                }
            }))
        }

        return itLoadByTime
    }
}