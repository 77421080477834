import { WeatherReading } from "@/core/interfaces"
import { ClimateSummary } from "@/core/interfaces/ClimateSummary"
import { Temperature } from "@/core/interfaces/Temperature"
import { jsonRpcService } from "@/core/services"
import { httpService } from "@/core/services/http.service"
import { Models } from "@ekko/predict-client-api"
import { Interface } from "predict-performance-calculation"
import { ClimateProfileData, StatProfileData, TMYProfileData } from "../interfaces/ClimateProfileData"
import { DateHour } from "../interfaces/DateHour"
import { TMYHourly } from "../interfaces/TMYHourly"

/**
 * Represents hourly weather information.
 * Each object can have either an 'hour' or 'time' property along with temperature (T2m) and relative humidity (RH).
 */
export type HourlyWeather = Interface.StatDatum | Interface.TMYDataPerTime
export type ClimateType = string | TMYHourly[] | DateHour<Temperature>[]

/**
 *
 */
export class ClimateHelper {
    /**
     *
     */
    private static readonly cache = {
        climateProfile: new Map<string, {data: ClimateProfileData}>(),
        climateProfileSite: new Map<string, ClimateProfileData>(),
        climateProfileSiteSummary: new Map<string, ClimateSummary["data"]>(),
        climateProfileSummary: new Map<string, ClimateSummary>(),
    }

    static defaultMinTemp = -10
    static defaultMaxTemp = 40

    /**
     * Returns a negative number if a < b, 0 if a == b, and a positive number if a > b.
     *
     * Unparseable values will be returned as whatever `unparseableValue` is.
     *
     * @param a
     * @param b
     * @param unparseableValue
     */
    private static dateCompareTMY(a: Date, b: string, unparseableValue = 0) {
        const timeMatch = b.match(/^.{4}(\d\d)(\d\d):(\d\d)/)

        if (!timeMatch) return unparseableValue
        const [month, day, hour] = timeMatch.slice(1)
        return a.getMonth() + 1 - +month ||
            a.getDate() - +day ||
            a.getHours() - (+hour - 1) // Note: TMY hours are 1-24 not 0-23.
    }

    /**
     * Determines the climate point based on the date
     *
     * @param climate
     * @param date
     * @param oneDayPerMonth
     */
    static async getClosest(climate: ClimateType, date: Date,
        oneDayPerMonth: boolean
    ) {
        if (Array.isArray(climate)) {
            return this.getClosestValues(climate, date)
        } else if (climate) {
            if(oneDayPerMonth) {
                return this.getClosestForReferenceSummary(climate, date)
            } else {
                return this.getClosestForReference(climate, date)
            }
        }
        return undefined
    }

    /**
     * Determines the climate point based on the date
     *
     * @param climate
     * @param date
     */
    static async getClosestForReference(climate: string, date: Date) {
        const month = date.getMonth() + 1

        const climateProfileData =
            await ClimateHelper.getClimateProfileData(climate)

        // climate profile as Stat format
        if ("StatMonth" in climateProfileData) {
            return ClimateHelper.getClosestStatMonthTemperature(
                climateProfileData as unknown as StatProfileData, month,
                date)
        }
        // climate profile TMY format
        else if ("TMYMonth" in climateProfileData) {
            return ClimateHelper.getClosestTMYMonthTemperature(
                climateProfileData as unknown as TMYProfileData, month,
                date)
        }
        return undefined
    }

    /**
     * Determines the climate point based on the date
     *
     * @param climate
     * @param date
     */
    static async getClosestForReferenceSummary(climate: string, date: Date) {
        const month = date.getMonth() + 1

        const climateProfileData =
            await ClimateHelper.getClimateProfileDataSummary(climate)

        return ClimateHelper.getClosestStatMonthTemperature(
            climateProfileData as unknown as StatProfileData, month, date)
    }

    /**
     * Determines the climate point based on the date
     *
     * @param climate
     * @param date
     */
    static async getClosestForSite(site: Pick<Models.SiteModel, "id">,
        date: Date
    ) {
        const month = date.getMonth() + 1

        const climateProfileData =
            await ClimateHelper.getClimateProfileSiteData(site)

        // climate profile as Stat format
        if ("StatMonth" in climateProfileData) {
            return ClimateHelper.getClosestStatMonthTemperature(
                climateProfileData as unknown as StatProfileData, month,
                date)
        }
        // climate profile TMY format
        else if ("TMYMonth" in climateProfileData) {
            return ClimateHelper.getClosestTMYMonthTemperature(
                climateProfileData as unknown as TMYProfileData, month,
                date)
        }
        return undefined
    }

    /**
     * Determines the climate point based on the date
     *
     * @param site
     * @param date
     */
    static async getClosestForSiteSummary(site: Pick<Models.SiteModel, "id">,
        date: Date
    ) {
        const month = date.getMonth() + 1

        const climateProfileData =
            await ClimateHelper.getClimateProfileSiteSummary(site)

        return ClimateHelper.getClosestStatMonthTemperature(
            climateProfileData as unknown as StatProfileData, month, date)
    }

    /**
     * Determines the climate point based on the date
     *
     * @param climate
     * @param date
     */
    static async getClosestValues(
        climate: TMYHourly[] | DateHour<Temperature>[],
        date: Date
    ) {
        // climate as DateHour<Temperature>
        if ("date" in climate[0]) {
            return ClimateHelper.getClosestTemperature(
                climate as DateHour<Temperature>[], date)
        }
        // climate as TMYHourly
        else if ("time" in climate[0]) {
            return ClimateHelper.getClosestTMYHourlyTemperature(
                climate as TMYHourly[], date)
        }
        return undefined
    }

    /**
     *
     * @param climate
     * @returns
     */
    public static async getClimateProfileData(climate: string) {
        let climateProfileResult = this.cache.climateProfile.get(climate)

        if (!climateProfileResult) {

            climateProfileResult = await httpService.getComputedClimate(climate)
            this.cache.climateProfile.set(climate, climateProfileResult)
        }
        return climateProfileResult.data
    }

    /**
     *
     * @param climate
     * @returns
     */
    public static async getClimateProfileDataSummary(climate: string) {
        const cachedValue = this.cache.climateProfileSummary.get(climate)
        if(cachedValue) {
            return cachedValue.data
        }

        const climateProfileResult = await httpService.getClimateProfileSummary(climate)
        this.cache.climateProfileSummary.set(climate, climateProfileResult)
        return climateProfileResult.data
    }

    /**
     *
     * @param climate
     * @returns
     */
    public static async getClimateProfileSiteData(
        site: Pick<Models.SiteModel, "id">
    ) {
        let climateProfileResult = this.cache.climateProfileSite.get(site.id)

        if (climateProfileResult === undefined) {
            climateProfileResult = await jsonRpcService.getSiteClimate(site)
            this.cache.climateProfileSite.set(site.id, climateProfileResult)
        }
        return climateProfileResult
    }

    /**
     *
     * @param site
     * @returns
     */
    public static async getClimateProfileSiteSummary(
        site: Pick<Models.SiteModel, "id">
    ) {
        const cachedValue = this.cache.climateProfileSiteSummary.get(site.id)
        if(cachedValue) {
            return cachedValue
        }

        const climateProfileResult =
            await jsonRpcService.getSiteClimateSummary(site)
        this.cache.climateProfileSiteSummary.set(site.id, climateProfileResult)
        return climateProfileResult
    }

    /**
     *
     * @param climate
     * @returns
     */
    static async getHighestRelativeHumidity(climate: string) {

        const climateProfileData = await ClimateHelper.getClimateProfileData(climate)

        if ("StatMonth" in climateProfileData) {
            return ClimateHelper.getHighestStatMonthRelativeHumidity(climateProfileData as unknown as StatProfileData)
        }

        else if ("TMYMonth" in climateProfileData) {
            return ClimateHelper.getHighestTMYMonthRelativeHumidity(climateProfileData as unknown as TMYProfileData)
        }
        return undefined
    }

    /**
     * Returns temperature boundaries or the defaults values
     * @param climate
     * @returns
     */
    static async getBoundaryTemperatures(climate: string): Promise<{ min: number, max: number }> {

        const climateProfileData = await ClimateHelper.getClimateProfileData(climate)
        let boundaries
        if ("StatMonth" in climateProfileData) {
            boundaries = ClimateHelper.getBoundaryStatMonthTemperatures(climateProfileData as unknown as StatProfileData)
        }

        else if ("TMYMonth" in climateProfileData) {
            boundaries = ClimateHelper.getBoundaryTMYMonthTemperatures(climateProfileData as unknown as TMYProfileData)
        }
        return boundaries? boundaries : { min: this.defaultMinTemp, max: this.defaultMaxTemp }
    }

    /**
     * Returns the closest TMY temperature to the date
     * @param climate
     * @param date
     */
    static getClosestTMYHourlyTemperature(climate: TMYHourly[], date: Date) {

        let matchingDatum = climate[climate.length - 1]

        for (const tmyDatum of climate) {
            if (this.dateCompareTMY(date, tmyDatum.time) < 0) break

            matchingDatum = tmyDatum

        }

        //This won't be reached since we gave initial value to matchingDatum
        // Should it be given null initially?
        if (!matchingDatum) {
            throw new Error(`No TMY data found for ${date.toISOString()}`)
        }

        return matchingDatum

    }

    /**
     * Returns the closest temperature to the date
     * @param climate
     * @param date
     * @returns
     */
    static getClosestTemperature(climate: DateHour<Temperature>[], date: Date) {

        let matchingDatum: Temperature | null = null

        for (const dateHourTemperature of climate) {

            const dateHour = new Date(dateHourTemperature.date)

            if (dateHour > date) {
                break
            }

            if (dateHour.getFullYear() === date.getFullYear() &&
                dateHour.getMonth() === date.getMonth() &&
                dateHour.getDate() === date.getDate() &&
                dateHourTemperature.hour > date.getHours()) {
                break
            }

            matchingDatum = dateHourTemperature.value
        }

        if (!matchingDatum) {
            throw new Error(`No temperature found for ${date.toISOString()}`)
        }

        return matchingDatum

    }


    /**
     * Returns the closest Stat month temperature to the date
     * @param climateProfileData
     * @param month
     * @param date
     */
    static getClosestStatMonthTemperature(climateProfileData: StatProfileData, month: number, date: Date) {

        const monthData = climateProfileData.StatMonth.find(m => m.month === month)!

        let matchingDatum = monthData.statDatum[monthData.statDatum.length - 1]

        for (const statDatum of monthData.statDatum) {

            if (statDatum.hour > date.getHours()) {
                break
            }

            matchingDatum = statDatum

        }

        return matchingDatum
    }

    /**
     * Returns the closest TMY month temperature to the date
     * @param climateProfileData
     * @param month
     * @param date
     */
    static getClosestTMYMonthTemperature(climateProfileData: TMYProfileData, month: number, date: Date) {

        const monthData = climateProfileData.TMYMonth.find(m => m.month === month)!

        let matchingDatum: Interface.TMYDataPerTime | null = null

        for (const tmyDatum of monthData.TMYData) {
            if (this.dateCompareTMY(date, tmyDatum.time) < 0) break

            matchingDatum = tmyDatum

        }

        if (!matchingDatum) {
            throw new Error(`No TMY data found close to ${date.toISOString()}`)
        }

        return matchingDatum
    }

    /**
     *  Returns the highest Stat month Relative humidity
     * @param climateProfileData
     * @returns
     */
    static getHighestStatMonthRelativeHumidity(climateProfileData: StatProfileData) {

        let maxRH = 0
        for (const statMonth of climateProfileData.StatMonth) {

            for (const statDatum of statMonth.statDatum) {
                maxRH = statDatum.RH > maxRH ? statDatum.RH : maxRH
            }
        }
        return maxRH

    }

    /**
     *  Returns the highest TMY month Relative humidity
     * @param climateProfileData
     * @returns
     */
    static getHighestTMYMonthRelativeHumidity(climateProfileData: TMYProfileData) {

        let maxRH = 0
        for (const tmyMonth of climateProfileData.TMYMonth) {

            for (const tmyData of tmyMonth.TMYData) {
                maxRH = tmyData.RH > maxRH ? tmyData.RH : maxRH
            }
        }
        return maxRH

    }

    /**
     *  Returns the boundary Stat month temperatures
     * @param climateProfileData
     * @returns
     */
    private static getBoundaryStatMonthTemperatures(climateProfileData: StatProfileData) {

        let min
        let max
        for (const statMonth of climateProfileData.StatMonth) {

            for (const statDatum of statMonth.statDatum) {

                if(!min && !max){
                    min = statDatum.T2m
                    max = statDatum.T2m
                }

                min = statDatum.T2m < min ? statDatum.T2m : min
                max = statDatum.T2m > max ? statDatum.T2m : max
            }
        }
        return (min && max)? {min, max} : null

    }

    /**
     *  Returns the boundary TMY month temperatures
     * @param climateProfileData
     * @returns
     */
    private static getBoundaryTMYMonthTemperatures(climateProfileData: TMYProfileData) {

        let min
        let max
        for (const statMonth of climateProfileData.TMYMonth) {

            for (const tmyMonth of statMonth.TMYData) {

                if(!min && !max){
                    min = tmyMonth.T2m
                    max = tmyMonth.T2m
                }

                min = tmyMonth.T2m < min ? tmyMonth.T2m : min
                max = tmyMonth.T2m > max ? tmyMonth.T2m : max
            }
        }
        return (min && max)? {min, max} : null

    }

    /**
     * Iterates over all climate points in a predictable and fairly consistent
     * format.
     *
     * Note: day will be null for stat data.
     *
     * @param climateProfileData
     */
    static *iterateClimate<T extends ClimateProfileData>(climateProfileData: T) {
        if ("TMYMonth" in climateProfileData) {
            for (const monthData of climateProfileData.TMYMonth) {
                for (const hourDatum of monthData.TMYData) {
                    const md = hourDatum.time.match(/(..):(..)/)
                    if (!md) {
                        throw new Error(
                            `Internal error: cannot parse time ${hourDatum.time}`)
                    }
                    yield {
                        month: monthData.month, day: +md[1],
                        hour: +md[2], ...hourDatum
                    }
                }
            }
        } else {
            for (const monthData of climateProfileData.StatMonth) {
                for (const hourDatum of monthData.statDatum) {
                    yield { month: monthData.month, day: null, ...hourDatum }
                }
            }
        }
    }

    /**
     * Iterates over all climate points in the date range. Note that this will
     * correctly handle periods overlapping the end of a year, and will iterate
     * them in the usual Jan-Dec order.
     *
     * @see iterateClimate()
     *
     * @param climateProfileData
     * @param from
     * @param to
     */
    static *iterateClimateInRange<T extends ClimateProfileData>(
        climateProfileData: T, from: Date, to: Date
    ) {
        /**
         * >0 if `to` is later in the year than `from`
         */
        const fromToDiff = (to.getMonth() - from.getMonth()) ||
            (to.getDate() - from.getDate()) ||
            (to.getHours() - from.getHours())
        for (const hourDatum of ClimateHelper.iterateClimate(climateProfileData)) {
            /**
             *
             * @param d
             * @returns >0 if h is later, <0 if h is earlier
             */
            const diff = (d: Date) => {
                return (hourDatum.month - d.getMonth() - 1) ||
                    (hourDatum.day ?
                        (hourDatum.day - d.getDate()) ||
                        (hourDatum.hour - d.getHours()) :
                        0)
            }

            if (fromToDiff < 0) {
                // Inverted, +++to---from+++
                if (diff(from) >= 0 || diff(to) <= 0) {
                    yield hourDatum
                }
            } else {
                // Normal, ---from+++to---
                if (diff(from) > 0 && diff(to) < 0) {
                    yield hourDatum
                // Special case for stat in the same month
                } else if (diff(from) == 0 && diff(to) <= 0) {
                    yield hourDatum
                }
            }
        }
    }

    /**
     * This returns the actual weather data with dryBulb or wetBulb filled if
     * needed.
     *
     * @param actualWeatherData
     * @param profileData
     * @returns Processed weather data
    */
    static fillWeatherReadingTemperature(
        actualWeatherData: Pick<WeatherReading, "dryBulb" | "wetBulb" | "relativeHumidity"> | null,
        profileData: Pick<HourlyWeather, "T2m" | "RH">
    ): Pick<WeatherReading, "dryBulb" | "wetBulb" | "relativeHumidity"> {
        if (!actualWeatherData) {
            return {
                dryBulb: profileData.T2m,
                wetBulb: ClimateHelper.wetBulbTemperature(profileData.T2m, profileData.RH),
                relativeHumidity: profileData.RH,
            }
        }

        const { dryBulb, wetBulb, relativeHumidity } = actualWeatherData

        let calculatedWetBulb = wetBulb
        let calculatedRelativeHumidity = relativeHumidity

        if (dryBulb) {
            calculatedRelativeHumidity ??= profileData.RH

            if (!calculatedWetBulb) {
                calculatedWetBulb = ClimateHelper.wetBulbTemperature(dryBulb, calculatedRelativeHumidity)
            }

            return {
                dryBulb,
                wetBulb: calculatedWetBulb,
                relativeHumidity: calculatedRelativeHumidity,
            }
        }

        if (calculatedWetBulb && calculatedRelativeHumidity) {
            return {
                dryBulb: ClimateHelper.dryBulbTemperature(calculatedWetBulb, calculatedRelativeHumidity),
                wetBulb: calculatedWetBulb,
                relativeHumidity: calculatedRelativeHumidity,
            }
        }

        return {
            dryBulb,
            wetBulb,
            relativeHumidity,
        }
    }

    /**
    *
    * @param dryBulb The dry bulb temperature (celsius)
    * @param relativeHumidityPercentPercent The RH value
    * @returns The wet bulb temperature, rounded to 1 decimal place
    */
    static wetBulbTemperature(dryBulb: number, relativeHumidityPercentPercent: number): number {
        // Formula from https://www.omnicalculator.com/physics/wet-bulb#how-to-calculate-the-wet-bulb-temperature
        const result = dryBulb * Math.atan(0.151977 * (relativeHumidityPercentPercent + 8.313659) ** (1 / 2)) +
            Math.atan(dryBulb + relativeHumidityPercentPercent) -
            Math.atan(relativeHumidityPercentPercent - 1.676331) +
            0.00391838 * (relativeHumidityPercentPercent) ** (3 / 2) * Math.atan(0.023101 * relativeHumidityPercentPercent) -
            4.686035
        // Round to 1dp
        return Math.round(result * 1e1) / 1e1
    }

    /**
    *
    * @param wetBulb The web bulb temperature
    * @param relativeHumidityPercent The RH value
    * @returns the calculated dry bulb temperature.
    */
    static dryBulbTemperature(wetBulb: number, relativeHumidityPercent: number): number {
        let floor = -50
		let ceiling = 50

		for (let i = 0; i < 10; i++) {
			const d = (floor + ceiling) / 2
			const w = this.wetBulbTemperature(d, relativeHumidityPercent)

			if (w < wetBulb) {
				floor = d
			} else {
				ceiling = d
			}
		}

		return floor
    }
}