import { ExternalVariableHumidity, OtherNodeType, ResourceType } from "@/core/enums"
import {
    ConnectionRuleHelper,
    NodeModelLabelHelper,
    NodeReferenceHelper,
    NodesetUpdater,
    StrategySplitterLabelHelper,
    VueLazyWrapper,
    vueLazySetMapValue
} from "@/core/helpers"
import { AnySplitterStrategy } from "@/core/helpers/NodesetUpdater/AnySplitterStrategy"
import { NodeGroup, UnplacedConnection, UnplacedNode } from "@/core/interfaces"
import { httpService, jsonRpcService } from "@/core/services"
import ApiService from "@/core/services/api.service"
import { Models, Services } from "@ekko/predict-client-api"
import { ConnectionType, NodeType } from "predict-performance-calculation"
import { v4 as uuidv4 } from "uuid"
import { ActionTree, GetterTree, MutationTree } from "vuex"
import { Preload } from "../../core/helpers/Preload"
import {
    AnyNode,
    AnyNodeModel,
    Connection,
    SiteViewState,
    SiteViewStateActions,
    SiteViewStateGetters,
    SiteViewStateMutations
} from "../SiteViewState"
import { NodeDefaultValues } from "./types"

type SplitterSupplier = Models.ItemModel | Models.PowerMeterPointModel | Models.CoolingMeterPointModel | Models.SplitterModel

class State extends SiteViewState {
    /**
     *
     */
    clonedNodes: AnyNode[] = []

    /**
     *
     */
    deletedConnection: { connection: Connection, nodes: AnyNode[] } | null = null

    /**
     *
     */
    locked = true

    /**
     *
     */
    selectedConnection: Connection | null = null

    /**
     *
     */
    siteAccess: Map<string, VueLazyWrapper<{ read: boolean, write: boolean }>> = new Map()

    /**
     *
     */
    unplacedConnection: UnplacedConnection | null = null

    /**
     *
     */
    unplacedNode: UnplacedNode | null = null
    /**
     *
     */
    updatedNode: AnyNode | null = null
}

const mutations: MutationTree<State> = {
    /**
     * @param state
     * @param siteConfiguration
     */
    addSiteConfiguration(state, siteConfiguration: Models.SiteConfigurationModel) {
        state.siteConfigurations?.push(siteConfiguration)
    },
    /**
     *
     * @param state
     * @param siteConfiguration
     */
    replaceSiteConfiguration(state, siteConfiguration: Models.SiteConfigurationModel) {
        if (state.siteConfigurations) {
            state.siteConfigurations = state.siteConfigurations.map(
                sc => sc.id == siteConfiguration.id ? siteConfiguration : sc
            )
        }
    },
    /**
     * @param state
     * @param nodes
     */
    setClonedNodes(state, nodes: AnyNode[]) {
        state.clonedNodes = nodes
    },
    /**
     * @param state
     * @param locked
     */
    setLocked(state, locked: boolean) {
        state.locked = locked
    },
    /**
     * @param state
     * @param selection
     */
    setSelectedConnection(state, selection: Connection | null) {
        state.selectedConnection = selection
    },
    /**
     * @param state
     * @param unplacedConnection
     */
    setUnplacedConnection(state, unplacedConnection: UnplacedConnection | null) {
        state.unplacedConnection = unplacedConnection
    },
    /**
     * @param state
     * @param unplacedNode
     */
    setUnplacedNode(state, unplacedNode: UnplacedNode | null) {
        state.unplacedNode = unplacedNode
    },
    /**
     *
     * @param state
     * @param connection
     */
    setDeletedConnection(state, connection: Connection | null) {
        if (connection) {
            state.deletedConnection = {
                connection,
                nodes: state.nodes!.filter(
                    n => {
                        const ref = NodeReferenceHelper.getNodeReference(n)
                        return ref == connection?.from || ref == connection?.to
                    }
                ),
            }
        } else {
            state.deletedConnection = null
        }
    },
    /**
     *
     * @param state
     * @param updatedNode
     */
    setUpdatedNode(state, updatedNode: State["updatedNode"]) {
        state.updatedNode = updatedNode
    },
}

const actions: ActionTree<State, any> = {
    /**
     *
     * @param actionState
     * @param nodes
     */
    async addClonedNodes({ dispatch, commit }, nodes: AnyNode[]) {
        await dispatch("addNodes", nodes)
        commit("setClonedNodes", [])
    },
    /**
     *
     * @param actionState
     * @param connection
     */
    async addConnection({ dispatch, state }, connection: Connection) {
        const fromNode = state.nodes?.find(
            n => n.type + "/" + n.id == connection.from
        )
        if (!fromNode) {
            throw new Error(`Unable to find node ${connection.from}`)
        }
        const toNode = state.nodes?.find(
            n => n.type + "/" + n.id == connection.to
        )
        if (!toNode) {
            throw new Error(`Unable to find node ${connection.to}`)
        }

        if (fromNode instanceof Models.SplitterModel) {

            switch (connection.type) {
                case "cooling":
                    {
                        const suppliers: SplitterSupplier[] = []

                        for await (const supplier of fromNode.getCooledBy()) {
                            suppliers.push(supplier)
                        }
                        suppliers.push(toNode as SplitterSupplier)

                        await fromNode.addCooledBy([toNode] as SplitterSupplier[])

                        const strategy = await fromNode.getSplitterDynamicCostStrategy()

                        if (strategy.type === "priorityCostStrategy") {
                            const service = await ApiService.getPriorityCostStrategyService()
                            const priorityCostStrategy = strategy as Models.PriorityCostStrategyModel

                            priorityCostStrategy.setNodeOrder(suppliers.map(s => `${s.type}/${s.id}`))

                            await service.updatePriorityCostStrategy(priorityCostStrategy)
                        }
                        break
                    }
                case "overhead":
                    throw new Error("Overhead connections not yet supported")
                case "power":
                    {
                        await fromNode.addPoweredBy([toNode] as SplitterSupplier[])
                    }
                    break
            }
        } else {
            switch (connection.type) {
                case "cooling":
                    await fromNode.addCooledBy(toNode as Models.ItemModel)
                    break
                case "overhead":
                    ConnectionRuleHelper.addOverhead(fromNode, toNode)
                    break
                case "power":
                    await fromNode.addPoweredBy(toNode as Models.ItemModel)
                    break
            }
        }

        dispatch("updateNode", fromNode)
    },
    /**
     *
     * @param actionState
     * @param siteConfiguration
     */
    async cloneSiteConfiguration({ commit, dispatch, state }, siteConfiguration: Models.SiteConfigurationModel) {
        const clonedId = await jsonRpcService.cloneConfiguration(siteConfiguration.id)
        if (typeof clonedId != "string") {
            throw new Error("Internal error: wrong type for cloned ID")
        }
        const siteConfigurationService = await ApiService.getSiteConfigurationService()
        const clonedSiteConfiguration: Models.SiteConfigurationModel = await siteConfigurationService.getSiteConfiguration(clonedId)

        commit("addSiteConfiguration", clonedSiteConfiguration)
        await dispatch("selectSiteConfiguration", clonedSiteConfiguration)
        const siteConfigurations = await dispatch("fetchSiteConfigurations")
        commit("setSiteConfigurations", siteConfigurations)

        // Update the cache
        const site = await clonedSiteConfiguration.getSite()
        site.addSiteConfigurations([clonedSiteConfiguration])
        if(state.site.id == site.id) {
            state.site.addSiteConfigurations([clonedSiteConfiguration])
        }
    },
    /**
     *
     * @param actionState
     * @param nodes
     */
    async dropBadPersistentReferences({ dispatch }, nodes: AnyNode[]) {
        const seenPersistentReference = new Set<string>()
        const duplicatePersistentReference = new Set<string>()
        for (const node of nodes) {
            const persistentReference = node.getPersistentReference()
            if (!persistentReference) {
                continue
            }
            if (seenPersistentReference.has(persistentReference)) {
                duplicatePersistentReference.add(persistentReference)
            } else {
                seenPersistentReference.add(persistentReference)
            }
        }
        if (duplicatePersistentReference.size > 0) {
            console.log(
                `Passively dropping ${duplicatePersistentReference.size} bad persistent references`
            )
            const updatedNodes: AnyNode[] = []
            for (const node of nodes) {
                const persistentReference = node.getPersistentReference()
                if (!persistentReference) {
                    continue
                }
                if (duplicatePersistentReference.has(persistentReference)) {
                    node.setPersistentReference(null)
                    updatedNodes.push(node)
                }
            }
            console.log(`${updatedNodes.length} nodes to be updated`)
            for (const node of updatedNodes) {
                await dispatch("updateNode", node)
            }
            console.log(`${updatedNodes.length} nodes updated`)
        }
    },
    /**
     *
     */
    async implementSiteConfiguration({ dispatch, state }) {
        const current = state.siteConfiguration
        if(!current) {
            throw new Error("No site configuration selected")
        }
        const service =
            await ApiService.getNamedService<Services.SiteConfigurationService>("SiteConfigurationService")
        const willFollow = await current.assertWillFollow()

        current.removeWillFollow(willFollow)
        current.addFollows(willFollow)
        await service.updateSiteConfiguration(current)

        willFollow.removeDrafts([current])
        willFollow.addFollowedBy(current)
        await service.updateSiteConfiguration(willFollow)

        await dispatch("updateSiteConfiguration", current)
    },
    /**
     * @param actionState
     * @param siteDataImportSite
     */
    async linkDataImportSite({ commit }, { site, dataImportSite }: {
        site: Models.SiteModel,
        dataImportSite: Models.SensorConfigSiteModel
    }) {
        const siteCbreMeterMappingService = await ApiService.getSiteCbreMeterMappingService()
        const siteCbreMeterMapping = new Models.SiteCbreMeterMappingModel()
        siteCbreMeterMapping.addSite(site)
        siteCbreMeterMapping.setSiteId(dataImportSite.id)
        return siteCbreMeterMappingService.addSiteCbreMeterMapping(siteCbreMeterMapping)
    },
    /**
     * @param actionState
     */
    async lock({ commit, dispatch }) {
        await dispatch("stopPlacing")
        commit("setLocked", true)
    },
    /**
     *
     * @param actionState
     * @param connection
     */
    async removeConnection({ dispatch, state }, connection: Connection) {
        const fromNode = state.nodes?.find(
            n => n.type + "/" + n.id == connection.from
        )
        if (!fromNode) {
            throw new Error(`Unable to find node ${connection.from}`)
        }
        const toNode = state.nodes?.find(
            n => n.type + "/" + n.id == connection.to
        )
        if (!toNode) {
            throw new Error(`Unable to find node ${connection.to}`)
        }

        switch (connection.type) {
            case "cooling":
                await fromNode.removeCooledBy(toNode as any)
                break
            case "overhead":
                ConnectionRuleHelper.removeOverhead(fromNode, toNode)
                break
            case "power":
                await fromNode.removePoweredBy(toNode as any)
                break
        }

        dispatch("updateNode", fromNode)
    },
    /**
     * @param actionState
     */
    unlock({ commit }) {
        commit("setLocked", false)
    },
    /**
     *
     * @param actionState
     * @param event
     * @returns
     */
    async addITProvisioningEvent({ dispatch }, event: Models.ITProvisioningEventModel) {
        const service = await ApiService.getITProvisioningEventService()
        const [storedEvent] = await service.addITProvisioningEvent(event)
        await dispatch("checkNodesValidity")
        return storedEvent
    },
    /**
     *
     * @param actionState
     * @param unplacedNode
     */
    async addNode({ commit, dispatch }, unplacedNode: UnplacedNode) {
        await dispatch("addNodes", [unplacedNode.node])
        await dispatch("selectNode", unplacedNode.node)
        commit("setUnplacedNode", null)
    },
    /**
     *
     * @param actionState
     * @param nodes
     */
    async addNodes({ dispatch, state }, nodes: AnyNode[]) {
        const storedNodes = await dispatch("storeNodes", nodes)

        if (state.nodes) {
            await dispatch("updateNodes", state.nodes.concat(storedNodes))
        }
    },
    /**
     *
     * @param actionState
     * @param event
     * @returns
     */
    async addOtherProvisioningEvent({ dispatch }, event: Models.OtherProvisioningEventModel) {
        const service = await ApiService.getOtherProvisioningEventService()
        const [storedEvent] = await service.addOtherProvisioningEvent(event)
        await dispatch("checkNodesValidity")
        return storedEvent
    },
    /**
     *
     * @param actionState
     * @param event
     * @returns
     */
    async addProvisioningEvent({ commit, dispatch }, event: Models.ProvisioningEventModel) {
        const service = await ApiService.getProvisioningEventService()
        const [storedEvent] = await service.addProvisioningEvent(event)
        commit("clearProvisioningCacheFor", await storedEvent.getItem())
        await dispatch("checkNodesValidity")
        return storedEvent
    },
    /**
     *
     * @param param0
     * @param resilienceModel
     */
    async addResilience({ dispatch }, resilienceModel: Models.ResilienceModel) {
        const service = await ApiService.getResilienceService()
        const [newResilience] = await service.addResilience(resilienceModel)
        await dispatch("checkNodesValidity")
        return newResilience
    },
    /**
     *
     * @param param0
     * @param resilienceModel
     */
    async removeResilience({ dispatch }, resilienceModel: Models.ResilienceModel) {
        const service = await ApiService.getResilienceService()
        await service.removeResilience(resilienceModel)
        await dispatch("checkNodesValidity")
    },
    /**
     * @param actionState
     */
    checkNodes({ commit }) {
        console.warn("Checking nodes is not yet supported")
    },
    /**
     *
     * @param actionState
     * @param nodes
     * @param x
     * @param y
     */
    async cloneNodes({ commit, dispatch, state }, cloningSet: { nodes: AnyNode[], x: number, y: number }) {
        return dispatch("performLayoutAction", {
            cloneNodes: async () => {
                const siteConfiguration = state.siteConfiguration
                if (!siteConfiguration) {
                    throw new Error(`Cannot clone nodes without a site configuration`)
                }

                const nodeSet: { type: string, id: string }[] = []
                for (const node of cloningSet.nodes) {
                    nodeSet.push({ type: node.type, id: node.id })
                }

                const clonedNodeRefs = await jsonRpcService.cloneNodes(siteConfiguration.id, nodeSet, cloningSet.x, cloningSet.y)

                let service
                let getNode
                const clonedNodes: AnyNode[] = []
                for (const ref of clonedNodeRefs) {
                    switch (ref.type) {
                        case ResourceType.COMBINERS:
                            service = await ApiService.getCombinerService()
                            getNode = async (n) => service.getCombiner(n)
                            break
                        case ResourceType.SPLITTERS:
                            service = await ApiService.getSplitterService()
                            getNode = async (n) => service.getSplitter(n)
                            break
                        case ResourceType.IT_NODES:
                            service = await ApiService.getITNodeService()
                            getNode = async (n) => service.getITNode(n)
                            break
                        case ResourceType.COOLING_METER_POINTS:
                            service = await ApiService.getCoolingMeterPointService()
                            getNode = async (n) => service.getCoolingMeterPoint(n)
                            break
                        case ResourceType.POWER_METER_POINTS:
                            service = await ApiService.getPowerMeterPointService()
                            getNode = async (n) => service.getPowerMeterPoint(n)
                            break
                        case ResourceType.ITEMS:
                            service = await ApiService.getItemService()
                            getNode = async (n) => service.getItem(n)
                            break
                        case ResourceType.OTHER_NODES:
                            service = await ApiService.getOtherNodeService()
                            getNode = async (n) => service.getOtherNode(n)
                            break
                        default:
                            throw new Error(`Node type ${ref.type} isn't supported`)
                    }
                    const node = await getNode(ref.id)
                    NodesetUpdater.Factory.forNode(node)?.connect()
                    clonedNodes.push(node)
                }
                commit("setClonedNodes", clonedNodes)
                if (state.nodes) {
                    const nodes = state.nodes.slice()
                    await dispatch("updateNodes", nodes.concat(clonedNodes))
                }
                await dispatch("reloadSiteConfigurationMetadata")
            }
        })
    },
    /**
     *
     * @param actionState
     * @param connection
     */
    async deleteConnection({ commit, dispatch, state }, connection: Connection) {
        /**
         * @param reference
         * @returns
         */
        function nodeFromReference(reference: string) {
            const [type, id] = reference.split("/")
            return state.nodes?.find(
                n => n.type == type && n.id == id
            )
        }
        const from = nodeFromReference(connection.from)
        const to = nodeFromReference(connection.to)
        if (from && to) {
            switch (connection.type) {
                case ConnectionType.COOLING:
                    {
                        await from.removeCooledBy(to as any)

                        if (from instanceof Models.SplitterModel) {
                            const strategy = await from.getSplitterDynamicCostStrategy()

                            if (strategy.type === "priorityCostStrategy") {
                                const service = await ApiService.getPriorityCostStrategyService()
                                const priorityCostStrategy = strategy as Models.PriorityCostStrategyModel
                                const nodeOrder = priorityCostStrategy.getNodeOrder()

                                if (nodeOrder) {
                                    const newNodeOrder = nodeOrder?.filter(no => no !== `${to.type}/${to.id}`)

                                    priorityCostStrategy.setNodeOrder(newNodeOrder)

                                    await service.updatePriorityCostStrategy(priorityCostStrategy)
                                }
                            }
                        }
                        break
                    }
                case ConnectionType.POWER:
                    await from.removePoweredBy(to as any)
                    break
                case ConnectionType.OVERHEAD:
                    if ("removeOtherSupplyBy" in from) {
                        await from.removeOtherSupplyBy(to as any)
                    }
                    break
                default:
                    throw new Error(`Unknown connection type ${connection.type}`)
            }

            await dispatch("updateNode", from)

            commit("setDeletedConnection", connection)
        }
    },
    /**
     *
     * @param actionState
     * @param node
     */
    async deleteNode({ commit, dispatch, state }, node: AnyNode) {
        return await dispatch("performLayoutAction", {
            deleteNode: async () => {
                const updater = NodesetUpdater.Factory.forNode(node)
                if(updater) {
                    await updater.remove()
                } else {
                    throw new Error(
                        `Cannot delete node of unrecognised type ${node.type}`)
                }

                if (state.nodes) {

                    commit(
                        "setNodes",
                        state.nodes.filter(
                            n => n.type != node.type || n.id != node.id
                        )
                    )
                    await dispatch("updateNodeOrder")
                    await dispatch("updateNodes", state.nodes.filter(n => n.type != node.type || n.id != node.id))
                }
                await dispatch("reloadSiteConfigurationMetadata")
            }
        })
    },

    /**
     *
     * @param actionState
     * @param hiddenDeviceTypes
     */
    async hideDeviceTypes({ commit, state }, hiddenDeviceTypes: string[]) {

        if (state.nodes) {
            state.visibleNodes = state.nodes?.filter(async (node) => {
                if (node.type === NodeType.Item) {
                    const item = (node as Models.ItemModel)
                    const deviceMode = await item.getDeviceMode()
                    return !hiddenDeviceTypes.includes(deviceMode.type)
                }
                else {
                    return node
                }
            })
        }
    },
    /**
     *
     * @param actionState
     * @param unplacedItemSpec
     * @returns
     */
    async placeItemOfDeviceType({ dispatch, state }, unplacedItemSpec: { type: string, event: MouseEvent }) {
        const possibleModels = state.deviceModels.filter(d => d.getDeviceModelType() == unplacedItemSpec.type)
        const defaultDeviceModel = possibleModels.find(dm => dm.getIsDefault())
        let mode: Models.DeviceModeModel | undefined

        if (defaultDeviceModel) {
            for await (const deviceMode of defaultDeviceModel.getDeviceModes()) {
                mode = deviceMode
                break
            }
        } else {
            let foundDeviceMode = false
            for (const deviceModel of possibleModels) {
                for await (const deviceMode of deviceModel.getDeviceModes()) {
                    mode = deviceMode
                    foundDeviceMode = true
                    break
                }
                if (foundDeviceMode) {
                    // exit the outer loop if a device mode has been found
                    break
                }
            }
        }

        if (!mode) {
            throw new Error(
                `No device mode found for type ${unplacedItemSpec.type}`
            )
        }

        const item = new Models.ItemModel()
        item.addDeviceMode(mode)
        item.addSiteConfiguration(state.siteConfiguration!)
        item.setName(NodeModelLabelHelper.get(unplacedItemSpec.type))
        item.setDescription("")
        item.setX(0)
        item.setY(0)
        item.setPersistentReference(uuidv4())

        return dispatch("placeNode", { node: item, type: unplacedItemSpec.type })
    },
    /**
     *
     * @param actionState
     * @param unplacedConnection
     */
    placeConnection({ commit }, unplacedConnection: UnplacedConnection | null) {
        commit("setUnplacedConnection", unplacedConnection)
    },
    /**
     *
     * @param actionState
     * @param unplacedNode
     */
    async placeNode({ commit, dispatch, state }, unplacedNode: UnplacedNode | null) {
        commit("setUnplacedNode", unplacedNode)
    },
    /**
     *
     * @param actionState
     * @param unplacedOtherLoadSpec
     * @returns
     */
    async placeOtherLoadOfType({ dispatch, state }, unplacedOtherLoadSpec: { type: string, event: MouseEvent }) {
        const otherLoad = new Models.OtherNodeModel()
        let modelTypeReference: OtherNodeType
        if (unplacedOtherLoadSpec.type === 'other') {
            modelTypeReference = OtherNodeType.GENERAL_LOAD
        } else {
            modelTypeReference = OtherNodeType.LIGHTING
        }
        otherLoad.setSupplyType(unplacedOtherLoadSpec.type)
        otherLoad.addSiteConfiguration(state.siteConfiguration!)
        otherLoad.setName(NodeModelLabelHelper.get(modelTypeReference))
        otherLoad.setDescription("")
        otherLoad.setX(0)
        otherLoad.setY(0)
        otherLoad.setPersistentReference(uuidv4())

        return dispatch("placeNode", { node: otherLoad, type: unplacedOtherLoadSpec.type })
    },
    /**
     *
     * @param actionState
     * @param unplacedNodeSpec
     * @returns
     */
    async placeSimpleNode({ dispatch, state }, unplacedNodeSpec: { type: NodeType, event: MouseEvent }) {
        const siteConfiguration = state.siteConfiguration
        if (!siteConfiguration) {
            throw new Error("No site configuration to attach to")
        }

        let node: AnyNodeModel
        let nodeLabelReference: string
        switch (unplacedNodeSpec.type) {
            case NodeType.Combiner:
                node = new Models.CombinerModel()
                nodeLabelReference = NodeType.Combiner
                break
            case NodeType.ITNode:
                node = new Models.ITNodeModel()
                nodeLabelReference = 'it'
                break
            case NodeType.CoolingMeterPoint:
                node = new Models.CoolingMeterPointModel()
                nodeLabelReference = 'meter'
                break
            case NodeType.PowerMeterPoint:
                node = new Models.PowerMeterPointModel()
                nodeLabelReference = 'meter'
                break
            default:
                throw new Error(`Node type ${unplacedNodeSpec.type} isn't supported`)
        }

        node.addSiteConfiguration(siteConfiguration)
        node.setName(NodeModelLabelHelper.get(nodeLabelReference))
        node.setDescription("")
        node.setX(0)
        node.setY(0)
        node.setPersistentReference(uuidv4())

        return dispatch("placeNode", { node, type: unplacedNodeSpec.type })
    },
    /**
     *
     * @param actionState
     * @param unplacedSplitterSpec
     * @returns
     */
    placeSplitterWithStrategy({ dispatch, getters, state }, unplacedSplitterSpec: { strategy: string, event: MouseEvent }) {
        let strategy: AnySplitterStrategy
        const defaultValues: NodeDefaultValues = getters.defaultValues
        switch (unplacedSplitterSpec.strategy) {
            case "cheapOneCostStrategy":
                strategy = new Models.CheapOneCostStrategyModel()
                strategy.setLowNode(null as unknown as string)
                break
            case "shareCostStrategy":
                strategy = new Models.ShareCostStrategyModel()
                strategy.setNodeShares([])
                break
            case "priorityCostStrategy":
                strategy = new Models.PriorityCostStrategyModel()
                strategy.setCapacityThreshold(defaultValues.splitter.Priority.capacityThreshold)
                strategy.setNodeOrder([])
                strategy.setEvenSplit(false)
                break
            case "environmentCostStrategy":
                strategy = new Models.EnvironmentCostStrategyModel()
                strategy.setLowNodes([])
                strategy.setCutoff(0)
                strategy.setLowPoint(0)
                strategy.setHighPoint(0)
                strategy.setExternalVariable(ExternalVariableHumidity.RelativeHumidity)
                break
            default:
                throw new Error(`Splitter strategy ${unplacedSplitterSpec.strategy} is unknown`)
        }
        const splitter = new Models.SplitterModel()

        splitter.addSiteConfiguration(state.siteConfiguration!)
        splitter.setName(StrategySplitterLabelHelper.get(unplacedSplitterSpec.strategy))
        splitter.setDescription("")
        splitter.setX(0)
        splitter.setY(0)
        splitter.setStaticCostHandler("")
        splitter.setPersistentReference(uuidv4())
        splitter.addSplitterDynamicCostStrategy(strategy)

        return dispatch("placeNode", { node: splitter, type: unplacedSplitterSpec.strategy })
    },
    /**
     *
     * @param param0
     */
    async reloadSiteConfigurationMetadata({ commit, state }) {
        const existing = state.siteConfiguration
        if(existing) {
            const service =
                await ApiService.getNamedService<Services.SiteConfigurationService>("SiteConfigurationService")
            const siteConfiguration =
                await service.getSiteConfiguration(existing.id)
            commit("setSiteConfiguration", siteConfiguration)
            await Preload.deviceModes(siteConfiguration).catch(
                e => console.warn("Preload failed", e))
        }
    },
    /**
     *
     * @param actionState
     * @param event
     */
    async removeITProvisioningEvent({ dispatch }, event: Models.ITProvisioningEventModel) {
        const service = await ApiService.getITProvisioningEventService()
        service.removeITProvisioningEvent(event)
        await dispatch("checkNodesValidity")
    },
    /**
     *
     * @param actionState
     * @param event
     */
    async removeOtherProvisioningEvent({ dispatch }, event: Models.OtherProvisioningEventModel) {
        const service = await ApiService.getOtherProvisioningEventService()
        service.removeOtherProvisioningEvent(event)
        await dispatch("checkNodesValidity")
    },
    /**
     *
     * @param actionState
     * @param event
     */
    async removeProvisioningEvent({ commit, dispatch }, event: Models.ProvisioningEventModel) {
        const service = await ApiService.getProvisioningEventService()
        commit("clearProvisioningCacheFor", await event.getItem())
        await service.removeProvisioningEvent(event)
        await dispatch("checkNodesValidity")
    },
    /**
     *
     * @param actionState
     * @param connection
     */
    selectConnection({ commit }, connection: Connection | null) {
        commit("setSelectedConnection", connection)
    },
    /**
     *
     * @param actionState
     */
    async stopPlacing({ commit, dispatch, state }) {
        if (state.unplacedConnection) {
            commit("setUnplacedConnection", null)
        }
        if (state.unplacedNode) {
            await dispatch("deleteNode", state.unplacedNode.node)
            commit("setUnplacedNode", null)
        }
    },
    /**
     *
     * @param actionState
     * @param nodes
     */
    async storeNodes({ dispatch }, nodes: AnyNode[]) {
        const nodesOut: AnyNode[] = []
        for (const node of nodes) {
            let nodeOut: AnyNode
            const updater = NodesetUpdater.Factory.forNode(node)
            if(updater) {
                nodeOut = await updater.add()
            } else {
                throw new Error(
                    `Cannot save node of unrecognised type ${node.modelType}`)
            }
            nodesOut.push(nodeOut)
        }
        await dispatch("reloadSiteConfigurationMetadata")
        return nodesOut
    },
    /**
     *
     * @param actionState
     * @param connectionRoute
     */
    async updateConnectionRoute({ dispatch, state }, { connection, route }: { connection: Connection, route: any }) {
        console.warn("Not yet supported")
    },
    /**
     *
     * @param actionState
     * @param event
     */
    async updateITProvisioningEvent({ dispatch }, event: Models.ITProvisioningEventModel) {
        const service = await ApiService.getITProvisioningEventService()
        await service.updateITProvisioningEvent(event)
        await dispatch("checkNodesValidity")
    },
    /**
     *
     * @param actionState
     * @param node
     */
    async updateNode({ commit, dispatch, state }, node: AnyNode) {
        const updater = NodesetUpdater.Factory.forNode(node)
        if(updater) {
            await updater.update()
        } else {
            throw new Error(
                `Cannot update node of unrecognised type ${node.modelType}`)
        }

        const nodes = state.nodes?.slice()
        const updatedNode = nodes?.find(n => n.id === node.id) ?? null
        commit("setUpdatedNode", updatedNode)

        dispatch("updateNodes", nodes)
    },
    /**
     *
     * @param actionState
     * @param event
     */
    async updateOtherProvisioningEvent({ dispatch }, event: Models.OtherProvisioningEventModel) {
        const service = await ApiService.getOtherProvisioningEventService()
        await service.updateOtherProvisioningEvent(event)
        await dispatch("checkNodesValidity")
    },
    /**
     *
     * @param actionState
     * @param event
     */
    async updateProvisioningEvent({ commit, dispatch }, event: Models.ProvisioningEventModel) {
        const service = await ApiService.getProvisioningEventService()
        await service.updateProvisioningEvent(event)
        commit("clearProvisioningCacheFor", await event.getItem())
        await dispatch("checkNodesValidity")
    },
    /**
     *
     * @param actionState
     * @param site
     */
    async updateSite({ commit }, site: Models.SiteModel) {
        const service = await ApiService.getSiteService()
        await service.updateSite(site)
        commit("setSite", site)
    },
    /**
     *
     * @param actionState
     * @param siteConfiguration
     */
    async updateSiteConfiguration({ commit, dispatch }, siteConfiguration: Models.SiteConfigurationModel) {
        const service = await ApiService.getSiteConfigurationService()
        await service.updateSiteConfiguration(siteConfiguration)
        const siteConfigurations = await dispatch("fetchSiteConfigurations")
        commit("replaceSiteConfiguration", siteConfiguration)
        await dispatch("selectSiteConfiguration", siteConfiguration)
        await dispatch("updateSiteConfigurations", siteConfigurations)
    },
    /**
     *
     * @param actionState
     * @param groups
     */
    async updateGroups({ commit, state }, groups: NodeGroup[]) {
        const service = await ApiService.getNodeGroupService()
        const siteConfiguration = state.siteConfiguration
        if (!siteConfiguration) {
            throw new Error(`Cannot update groups without a site configuration`)
        }
        for await (const nodeGroup of siteConfiguration.getNodeGroups()) {
            service.removeNodeGroup(nodeGroup)
        }

        for (const group of groups) {
            const nodeGroup = new Models.NodeGroupModel()
            nodeGroup.setColour(group.colour)
            nodeGroup.setName(group.name)
            // Empty Proxy and unparsed array fails to be backed up in indexedDB
            nodeGroup.setNodes(JSON.parse(JSON.stringify(group.nodes)))

            nodeGroup.addSiteConfiguration(siteConfiguration)
            await service.addNodeGroup(nodeGroup)
        }
        commit("setGroups", groups)
    },
    /**
     *
     * @param actionState
     */
    async updateNodeOrder({ state }) {
        const splitters = state.nodes?.filter(s => s.type === "splitter")

        if (splitters) {
            for (const spl of splitters) {
                const splitter = spl as Models.SplitterModel
                const suppliers: SplitterSupplier[] = []

                for await (const supplier of splitter.getCooledBy()) {
                    suppliers.push(supplier)
                }

                const strategy = await splitter.getSplitterDynamicCostStrategy()

                if (strategy.type === "priorityCostStrategy") {
                    const service = await ApiService.getPriorityCostStrategyService()
                    const priorityCostStrategy = strategy as Models.PriorityCostStrategyModel

                    priorityCostStrategy.setNodeOrder(suppliers.map(s => `${s.type}/${s.id}`))

                    await service.updatePriorityCostStrategy(priorityCostStrategy)
                }
            }
        }
    },
    /**
     *
     * @param actionState
     * @param node
     */
    selectNode({ commit }, node: AnyNode | null) {
        if (node) {
            const nodeReference = node.type + "/" + node.id
            commit("setSelectedNode", nodeReference)
        } else {
            commit("setSelectedNode", null)
        }
        commit("setSelectedNodeObject", node)
    },
}

/**
 *
 */
const getters: GetterTree<State, any> = {
    /**
     *
     * @param state
     * @returns
     */
    defaultValues(state): NodeDefaultValues {
        return {
            splitter: {
                Priority: {
                    capacityThreshold: 0.9,
                },
            },
        }
    },
    /**
     * @param state
     */
    getLocked(state): boolean {
        return state.locked
    },
    /**
     * @param state
     */
    siteAccessFor(state) {
        return (site: Models.SiteModel) => vueLazySetMapValue(
            state.siteAccess,
            site.id,
            () => httpService.siteAccessLevel(site.id!),
            { read: false, write: false }
        )
    },
}

const EditViewModule = {
    namespaced: true,
    state: {
        ...new State(),
    },
    getters: {
        ...SiteViewStateGetters,
        ...getters,
    },
    mutations: {
        ...SiteViewStateMutations,
        ...mutations
    },
    actions: {
        ...SiteViewStateActions,
        ...actions
    },
}

export default EditViewModule

